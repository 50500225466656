.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carrousel-container {
    width: 100vw;
    height: 100vh;
}

.carousel-inner {
    height: 100%;
}

.carousel-indicators > button {
    background-color: rgb(27, 24, 19) !important;
}
  
.project-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.project-item-container {
    background-color: rgba(255, 255, 255, 0.685);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 10px;
    width: 75vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-item-show {
    width: 50vw;
    height: 100%;
}

.img-item-container {
    display: flex;
    justify-content: center;
    align-items: end;
    height: 80%;
    width: 100%;
}

.item-img {
    margin: 0px 15px 0px;
    width: 48vw;
    height: 50vh;
    border-radius: 15px;
}

.item-link-container {
    width: 100%;
    height: 20%;
    padding: 0% 0% 0% 0%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icons-link-container {
    width: 15%;
    display: flex;
    justify-content: space-evenly;
}

.icon-link {
    font-size: 22px;
    color: rgb(27, 24, 19);
}

.name-project {
    font-size: 20px;
    font-weight: 900;
    color: rgb(27, 24, 19);
    margin: 0px 10px 0px 15px;
}

.proyect-item-description {
    width: 55vw;
    height: 100%;
    color: rgb(27, 24, 19);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.project-description-list {
    font-size: 17px;
    width: 82%;
    padding: 10px; 
}
  
.project-description-list li {
    margin-bottom: 5px;
}

.buttons {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    border: 1px solid #3d2a1d;
    color: rgba(255, 255, 255, 0.685);
    background-color: #3d2a1d;
}

.icon-link:hover {
    transform: scale(1.2);
    transition: all 0.0s;
    color: #3d2a1d;
}

@media (max-width: 530px) {
  
    .container {
        display: flex;
        align-items: center;
    }

    .carrousel-container {
        width: 100%;
        height: 85%;
    }
    
    .carousel-inner {
        height: 100%;
    }
    
    .project-item-container {
        width: 95%;
        height: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .project-item-show {
        width: 85%;
        height: 100%;
    } 
    
    .item-img {
        width: 90%;
        height: 70%;
        margin: 10px 0px 15px;
    }
    
    .icon-link {
        margin-left: 15px;
    }
    
    .project-description-list {
        font-size: 15px;
        width: 80%;
    }
    
    .proyect-item-description {
        width: 100%;
        height: 100%;
    }

    .project-item-container:hover {
        transform: none;
        transition: none;
    }
}