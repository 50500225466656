.skills-container {
    height: 100vh;
    width: 100%;
}

.skills-carrousel-container {
    width: 100%;
    height: 100%;
}

.skills-carousel-item-container {
    width: 100%;
    height: 100%;
    padding: 10%;
}

.skills-item-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 20%);
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.skills-item {
    padding-top: 2%;
    margin: 0% 10% 0%;
    width: 80%;
    display: grid;
    align-items: center;
    justify-content: center;
    color: rgb(27, 24, 19);
}

.skills-item p {
    text-align: center;
}

.item-skill {
    width: 100px;
    height: 100px;
}

.express-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: gray;
    border-radius: 50%;
}

.skills-item:hover {
    transform: scale(1.4);
    transition: all 0.0s;
}

.image-mui-skill {
    width: 110px;
    height: 105px
} 

.image-apirest-skill {
    width: 110px;
    height: 80px;
    margin-bottom: 14px
}

@media (max-width: 530px) {

    .skills-container {
        display: flex;
        align-items: center;
    }

    .skills-carrousel-container {
        width: 100%;
        height: 85%;
    }

    .skills-item-container {
        grid-template-columns: repeat(3, 25%);
    }

    .item-skill {
        width: 85px;
        height: 85px;
    }

    .image-apirest-skill {
        width: 90px;
        height: 70px;
        margin: 10px 20px 0px 15px;
    }

    .image-mui-skill {
        width: 110px;
        height: 85px
    } 

    .express-image {
        height: 75px;
    }

    .skills-item {
        width: 100%;
    }

    .skills-item:hover {
        transform: none;
        transition: none;
      }
}