* {
  font-family: sans-serif
}

body {
  margin: 0;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.685);
}

#root {
  height: 100%;
  width: 100%;
}

