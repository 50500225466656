.container-general {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
}

.introduccion-container {
    width: 80%;
    height: 40%;
    color: rgb(27, 24, 19);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.conoceme-button {
    background-color: #472f1f; 
    color: #fff; 
    border: none; 
    border-radius: 8px; 
    padding: 10px 20px; 
    font-size: 18px; 
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; 
  }
  
  .conoceme-button:hover {
    background-color: #472f1f9f; 
  }

.image-me-container {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-me {
    width: 50%;
    height: 50%;
    border-radius: 100%;
}

.image-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 530px) {
  
    .container-general {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .image-me-container {
        height: 50%;
        display: flex;
        align-items: end;
        justify-content: center;
    }

    .image-me {
        height: 50%;
        width: 80%;
    }
}