.about-me-container {
    width: 100vw;
    height: 100vh;
    padding-top: 4.5%;
    display: flex;
    flex-direction: row;
}

.image-container {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 45%;
    height: 45%;
    border-radius: 25px; 
    display: flex;
    align-items: center;
}

.about-me-presentation-container {
    width: 55%;
    color:  rgb(27, 24, 19);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.about-me-presentation-container>h3 {
    width: 75%;
    font-size: 17px;
}

.about-me-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
}

.line-title {
    width: 30%;
    border: 1px solid #3d2a1d;
    height: 0px
}

.about-me-pages-container {
    width: 50%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
}

.about-me-pages {
    color:  rgb(27, 24, 19);
    font-size: 35px;
    text-decoration: none;
    position: relative;
}
  
.about-me-pages-cv {
    font-size: 38px;
}

.about-me-pages:hover {
    color: #3d2a1d;
    transform: scale(1.1);
    transition: all 0.0s;
}

.image:hover {
    transform: scale(1.1);
    transition: all 0.0s;
}

@media (max-width: 530px) {
    .about-me-container {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .image-container {
        width: 100%;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .about-me-presentation-container {
        width: 100%;
        height: 60%;
        justify-content: space-evenly;
    }

    .about-me-presentation-container>h3 {
        width: 85%;
    }

    .image {
        margin-top: 10vh;
        width: 80%;
        height: 85%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image:hover {
        transform: none;
        transition: none;
    }

    .about-me-title {
        width: 90%;
    }
}