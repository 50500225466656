.contact-container-general {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container {
    width: 45vw;
    height: 45%;
    color: rgb(27, 24, 19);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.items-container {
    height: 30%;
    font-size: 100%;
    display: flex;
    align-items: center;
}

.iconos {
    width: 10%;
    height: 50%;
    margin-right: 10px;
}

.linkedin-icon {
    font-size: 45px;
    margin: 0px 2% 0px 1%;
}

.enlace-in {
    cursor: pointer;
}

@media (max-width: 530px) {
    .contact-container-general {
        margin-left: 10px;
        height: 100vh;
    }

    .contact-container {
        width: 100%;
        height: 50%;
        margin-left: 10px;
    }

    .enlace-in {
        font-size: 15px;
    }

    .linkedin-icon {
        font-size: 55px;
        margin-left: 8px;
    }

    .iconos {
        width: 12%;
        height: 52%;
        margin-left: 10px;
    }
}